var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dynamic-scroller',{attrs:{"items":_vm.items,"min-item-size":50,"key-field":_vm.itemKey,"page-mode":""},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('div',{staticClass:"table-row table-header v-data-table-header"},[(_vm.selectable === true)?_c('th',{staticClass:"table-td table-th td-checkbox"},[_c('v-simple-checkbox',{attrs:{"color":"primary","value":_vm.items.length > 0 && _vm.selected.length === _vm.items.length,"ripple":false},on:{"click":function($event){return _vm.itemsSelectAll()}}})],1):_vm._e(),_vm._l((_vm.headers),function(header){return _c('th',{key:header.value,staticClass:"table-td table-th",class:[
          ("justify-" + (_vm.justifyStyle[header.align])),
          {
            'cursor-pointer': header.sortable !== false,
            asc: _vm.sorts.sortDesc[0] === false,
            desc: _vm.sorts.sortDesc[0] === true,
            sortable: _vm.sorts.sortBy.indexOf(header.value) >= 0,
            active: _vm.sorts.sortBy.indexOf(header.value) >= 0,
          }
        ],style:(_vm.makeHeaderStyle(header)),attrs:{"aria-label":header.text},on:{"click":function($event){return _vm.updateSort(header)}}},[_c('span',[_vm._v(_vm._s(header.text))]),(header.sortable !== false)?_c('i',{staticClass:"\n            v-icon notranslate\n            v-data-table-header__icon mdi mdi-arrow-up theme--light\n          ",staticStyle:{"font-size":"18px"},attrs:{"aria-hidden":"true"}}):_vm._e()])})],2),(_vm.loading === true)?_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","color":"primary"}}):_vm._e(),(_vm.loading === true && _vm.items.length === 0)?_c('div',{staticClass:"table-row"},[_c('div',{staticClass:"empty-td"},[_vm._v("불러오는 중...")])]):_vm._e(),(_vm.loading === false && _vm.items.length === 0)?_c('div',{staticClass:"table-row"},[_c('div',{staticClass:"empty-td"},[_vm._v("데이터가 없습니다.")])]):_vm._e()]},proxy:true},{key:"default",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
        var active = ref.active;
return [_c('dynamic-scroller-item',{attrs:{"item":item,"active":active,"size-dependencies":[item.memo]}},[_c('div',{staticClass:"table-row"},[(_vm.selectable === true)?_c('div',{staticClass:"table-td td-checkbox"},[_c('v-simple-checkbox',{attrs:{"color":"primary","value":_vm.returnObject === true
            ? _vm.selected.indexOf(item) !== -1
            : _vm.selected.indexOf(item[_vm.itemKey]) !== -1,"ripple":false},on:{"click":function($event){_vm.returnObject === true
            ? _vm.currentSelected(item) : _vm.currentSelected(item[_vm.itemKey])}}})],1):_vm._e(),_vm._l((_vm.headers),function(header){return _c('div',{key:header.value,staticClass:"table-td",class:("justify-" + (_vm.justifyStyle[header.align])),style:(_vm.makeHeaderStyle(header))},[_vm._t(header.value,function(){return [_vm._v(" "+_vm._s(item[header.value])+" ")]},{"item":item})],2)})],2)])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }