<template>
  <dynamic-scroller
    :items="items"
    :min-item-size="50"
    :key-field="itemKey"
    page-mode
  >
    <template #before>
      <div class="table-row table-header v-data-table-header">
        <th
          class="table-td table-th td-checkbox"
          v-if="selectable === true"
        >
          <v-simple-checkbox
            color="primary"
            :value="items.length > 0 && selected.length === items.length"
            @click="itemsSelectAll()"
            :ripple="false"
          />
        </th>
        <th
          class="table-td table-th"
          v-for="header in headers"
          :key="header.value"
          :aria-label="header.text"
          :style="makeHeaderStyle(header)"
          :class="[
            `justify-${justifyStyle[header.align]}`,
            {
              'cursor-pointer': header.sortable !== false,
              asc: sorts.sortDesc[0] === false,
              desc: sorts.sortDesc[0] === true,
              sortable: sorts.sortBy.indexOf(header.value) >= 0,
              active: sorts.sortBy.indexOf(header.value) >= 0,
            }
          ]"
          @click="updateSort(header)"
        >
          <span>{{ header.text }}</span>
          <i
            v-if="header.sortable !== false"
            aria-hidden="true"
            class="
              v-icon notranslate
              v-data-table-header__icon mdi mdi-arrow-up theme--light
            "
            style="font-size: 18px;"
          />
        </th>
      </div>
      <v-progress-linear
        v-if="loading === true"
        indeterminate
        absolute
        color="primary"
      />
      <div
        v-if="loading === true && items.length === 0"
        class="table-row"
      >
        <div class="empty-td">불러오는 중...</div>
      </div>
      <div
        v-if="loading === false && items.length === 0"
        class="table-row"
      >
        <div class="empty-td">데이터가 없습니다.</div>
      </div>
    </template>
    <template v-slot="{ item, index, active }">
      <dynamic-scroller-item
        :item="item"
        :active="active"
        :size-dependencies="[item.memo]">
        <div class="table-row">
          <div
            v-if="selectable === true"
            class="table-td td-checkbox"
          >
            <v-simple-checkbox
              color="primary"
              @click="returnObject === true
              ? currentSelected(item) : currentSelected(item[itemKey])"
              :value="returnObject === true
              ? selected.indexOf(item) !== -1
              : selected.indexOf(item[itemKey]) !== -1"
              :ripple="false"
            />
          </div>
          <div
            v-for="header in headers"
            :key="header.value"
            :class="`justify-${justifyStyle[header.align]}`"
            class="table-td"
            :style="makeHeaderStyle(header)"
          >
            <slot
              :name="header.value"
              :item="item"
            >
              {{ item[header.value] }}
            </slot>
          </div>
        </div>
      </dynamic-scroller-item>
    </template>
  </dynamic-scroller>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default {
  name: 'DataTable',
  components: {
    DynamicScroller,
    DynamicScrollerItem,
  },
  computed: {
    ...mapGetters({
    }),
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    sorts: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: 'no',
    },
    selected: {
      type: Array,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    func: {
      type: Function,
    },
    options: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    justifyStyle: {
      right: 'end',
      left: 'start',
      center: 'center',
    },
  }),
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
    }),
    makeHeaderStyle(header) {
      if (header.width === undefined) {
        return 'flex-grow: 1; min-width: 100px';
      }
      return `width: ${header.width}px;
        max-width: ${header.width}px;
        min-width: ${header.width}px;
        flex-grow: 0;
        flex-shrink: 0;`;
    },
    updateSort(header) {
      if (header.sortable !== false && this.loading === false) {
        this.$emit('update:sorts', {
          sortBy: [header.value, this.itemKey],
          sortDesc: [!this.sorts.sortDesc[0], true],
        });
      }
    },
    itemsSelectAll() {
      if (this.items.length > 0 && this.selected.length === this.items.length) {
        this.$emit('update:selected', []);
      } else if (this.returnObject === true) {
        this.$emit('update:selected', this.items);
      } else {
        this.$emit('update:selected', this.items.map((r) => r[this.itemKey]));
      }
      this.progress(false);
    },
    currentSelected(item) {
      if (this.selected.indexOf(item) !== -1) {
        this.$emit('update:selected', this.selected.filter((r) => r !== item));
      } else {
        this.$emit('update:selected', [...this.selected, item]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.tbody-wrap {
  padding-top: 4px;
  position: relative;
}
.scroller {
  height: calc(100vh - 400px);
  width: calc(100vw - 116px);
  min-width: 1200px;
}
.table-row {
  display: flex;
  overflow: hidden;
  min-height: 50px;
  .empty-td {
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: center;
    font-size: 0.9rem;
    color: #00000099;
  }
  &.table-header {
    background-color: #f9f9f9;
    padding: 0px 0px;
    height: 32px;
    border-top: thin solid rgba(0, 0, 0, 0.12);
    .table-th {
      font-size: 12px;
      color: #00000099;
      cursor: default !important;
      &.cursor-pointer {
        cursor: pointer !important;
      }
    }
  }
}
.table-td {
  display: flex;
  align-items: center;
  // padding: 0px 10px;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  font-size: 14px;
  overflow: hidden;
  line-break: anywhere;
  max-width: 588px;
  &.td-checkbox {
    width: 32px;
    // min-width: 32px;
    // max-width: 32px;
    padding: 0px;
    justify-content: center;
    flex-shrink: 0;
  }
}
.v-simple-checkbox {
  .v-input--selection-controls__input {
    margin-right: 0;
  }
}
.justify-undefined {
  justify-content: center;
}
</style>
